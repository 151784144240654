import React, { useState, useEffect, useRef } from 'react';
import './ChatComponent.css';
import { run_gpt_example } from '../../common/nip105-client';
import gpt_icon from '../../media/images/services/gpt-icon.svg';
import ReactMarkdown from 'react-markdown';
import { BeatLoader } from 'react-spinners';
import { ActiveTabProvider, useActiveTab } from '../../common/ActiveTabContext';
import { Textarea, Button } from "@material-tailwind/react"; // Import Textarea and Button from Material Tailwind

const ChatComponent = ({paymentChoiceStatus, setPaymentChoiceStatus}) => {
  const [chatHistory, setChatHistory] = useState([
    // { role: 'user', content: "You: " + "howdy" },
    // { role: 'assistant', content: "ChatGPT: " + "howdy howdy howdy howdy howdy howdy howdy howdy" }
  ]);
  const [inputText, setInputText] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const { activeTab, setActiveTab } = useActiveTab();
  const chatRef = useRef(null);

  const sendMessage = async () => {
    if (inputText.trim() !== '') {
      setIsLoading(true);
      setChatHistory((prevHistory) => [
        ...prevHistory,
        { role: 'user', content: "You: " + inputText },
      ]);

      performJob(inputText);
    }
  };

  async function performJob(inputTextData) {
    try {
      const updatedChatHistory = [
        ...chatHistory,
        { role: 'user', content: "You: " + inputTextData },
      ];
  
      const result = await run_gpt_example(updatedChatHistory); // Pass updated chat history
      console.log("result from GPT performJob:", result);
  
      if (result.authCategory && result.authCategory === "not-selected") {
        setPaymentChoiceStatus("choosePaymentMethod");
        setIsLoading(false);
        return;
      }
  
      setChatHistory((prevHistory) => [
        ...prevHistory,
        { role: 'assistant', content: "ChatGPT: " + result },
      ]);
    } finally {
      setIsLoading(false);
    }
  
    setInputText('');
  }
  

  const handleKeyPress = (event) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault(); // Prevent the default action to avoid inserting a new line
      sendMessage();
    }
    // No need for an else clause here; a new line will be inserted by default if Shift+Enter is pressed
  };

  useEffect(() => {
    chatRef.current.scrollTop = chatRef.current.scrollHeight;
  }, [chatHistory]);

  useEffect(() => {
    const handlePopState = () => {
        // Extract the tab parameter from the URL
        const params = new URLSearchParams(window.location.search);
        const tab = params.get('tab');

        // Update the active tab based on the URL, or reset to default if no tab is specified
        if (tab) {
            setActiveTab(tab);
        } else {
            // Reset to default state if back at main page without specific tab
            setActiveTab(null); // Or whatever your default state should be
        }
    };

    window.addEventListener('popstate', handlePopState);

    return () => window.removeEventListener('popstate', handlePopState);
}, [setActiveTab]);

  return (
    <div className="chat-container">
      <div className="header-container mt-10 flex items-center select-none" style={{paddingTop:'100px', marginBottom:'12px'}}>
        <div className="w-8 h-8 md:w-16 md:h-16 rounded-xl overflow-hidden bg-gradient-to-r from-orange-500 to-red-500 flex-shrink-0 mr-4">
          <img src={gpt_icon} alt="GPT Icon" className="w-full h-full object-cover" style={{padding:'8px'}} />
        </div>
        <h1 className="gpt-title font-bold">
          Proxied GPT3.5 Turbo Service
        </h1>
      </div>
      <div className="chat-history" ref={chatRef} style={{ maxHeight: '500px', overflowY: 'auto' }}>
        {chatHistory.map((message, index) => (
          <div key={index} className={`chat-message-${message.role}`}>
            <ReactMarkdown
              components={{
                code: ({ node, inline, className, children, ...props }) => {
                  const match = /language-(\w+)/.exec(className || '');
                  return !inline && match ? (
                    <pre className={`custom-code ${match[1]}`}>
                      <code className={`custom-code-text`} {...props}>
                        {String(children).replace(/\n$/, '')}
                      </code>
                    </pre>
                  ) : (
                    <code className={className} {...props}>
                      {children}
                    </code>
                  );
                },
              }}
            >
              {message.content}
            </ReactMarkdown>
          </div>
        ))}
      </div>
      <div className="chat-input">
        <Textarea
          value={inputText}
          style={{ color: 'white',backgroundColor: isLoading ? 'gray' : 'black' }}
          onChange={(e) => setInputText(e.target.value)}
          onKeyPress={handleKeyPress}
          placeholder="Type your message proxied ChatGPT prompt..."
          disabled={isLoading}
        />
        <Button
          onClick={sendMessage}
          className="mt-2 ml-2 run-button-custom select-none" // Use Material Tailwind classes for margin-top and margin-left
          disabled={isLoading}
        >
          {isLoading ? (
            <BeatLoader color="white" size={8} loading={true} />
          ) : (
            <strong>Run</strong>
          )}
        </Button>
      </div>
    </div>
  );
};

export default ChatComponent;
